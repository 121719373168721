import React, { useEffect, useRef, useState } from 'react';
import './news.css';

import backdrop_png from '../../assets/images/alpha_announcement/png/backdrop.png';
import backdrop_webp from '../../assets/images/alpha_announcement/webp/backdrop.webp';
import drone1_webp from '../../assets/images/alpha_announcement/webp/drone1.webp';
import drone1_png from '../../assets/images/alpha_announcement/png/backdrop.png';

import drone_combat_mobile_png from '../../assets/images/alpha_announcement/png/drone_combat_mobile.png';
import drone_combat_mobile_webp from '../../assets/images/alpha_announcement/webp/drone_combat_mobile.webp';

import kickstarter_png from '../../assets/images/KickstarterFNScapsule.png';
import kickstarter_webp from '../../assets/images/KickstarterFNScapsule.webp';

const News = () => {


  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const targetDate = new Date('2024-11-21T23:36:00Z'); // Fri, November 22, 2024, 8:36 AM UTC+09:00
    const countdownDate = new Date(targetDate.getTime() - 17 * 24 * 60 * 60 * 1000);

    const updateCountdown = () => {
      const now = new Date();
      const timeLeft = targetDate - now;
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
      const seconds = Math.floor((timeLeft / 1000) % 60);
      setTimeLeft({ days, hours, minutes, seconds });
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

  {/*
  const droneRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          droneRef.current.classList.add('news-drone1-animate');
          observer.unobserve(entries[0].target);
        }
      },
      {
        threshold: 1,
        rootMargin: '-4%',
      }
    );

    observer.observe(droneRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);
  */}

  return (
    <div className='papercactus__news_backgroundColor'>
        <div className='papercactus__news-kickstarter section__padding'>
          <a href="https://www.kickstarter.com/projects/foxandshadow/fox-and-shadow/" target="_blank" rel="noreferrer">
            <picture>
              <source srcSet={kickstarter_webp} type='image/webp' />
              <source srcSet={kickstarter_png} type='image/png' />
              <img src={kickstarter_webp} alt="Kickstarter" className='kickstarter-image' />
            </picture>
          </a>
          <a href="https://www.kickstarter.com/projects/foxandshadow/fox-and-shadow/" target="_blank" rel="noreferrer">
          <div className="countdown-timer">
            <p>{`${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s remain`}</p>
            <p>Click here for the Kickstarter!!!</p>
          </div>
          </a>
        </div>
        {/*    
        <div className='papercactus__news section__padding'>
        <div className='division__CTA_panel_animation' style={{ height: '940px', width: '110%', zIndex: '1', left: '-10%', backgroundImage: `url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='40' patternTransform='scale(1) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(212, 59%, 64%, 0.35)'/><path d='M0 10h20z' stroke-width='0.5' stroke='hsla(199, 100%, 76%, 0.29)' fill='none'/><path d='M0 30h20z' stroke-width='0.5' stroke='hsla(185, 0%, 100%, 0.35)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,-160)' fill='url(%23a)'/></svg>")` }} />
        <div className='papercactus__CTA_panel_animation_gradiant_top'></div>
        <div className='papercactus__news_section'>
          <div className="papercactus__news-title-backing">
            <h1 style={{ letterSpacing: '0.3rem' }}>COMBAT ALPHA</h1>
          </div>
          <p className='papercactus__news-paragraph' style={{ paddingBottom: '25px' }}>Combat Alpha has been released for testing for lucky players on the PGC Discord! Fight back the bots, improve your deck and hunt for scraps in the post-apocolyptical world of Fox and Shadow.</p>
          <p>Join the Paper Cactus Games Discord for more information on how to join on future game alpha and beta tests!</p>
          <div className="division__CTA">
            <a className='papercactus__socials-social_icon' href="https://discord.com/invite/XuXajDW" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-discord" style={{ fontSize: '2.2rem' }}></i>
            </a>
          </div>
        </div>
        <div className="news-image-container">
          <div className='news-image-container-mask'>
            <picture id="backdrop1" alt="backdrop1" className='news-backdrop1' ref={droneRef}>
              <source srcSet={backdrop_webp} type='image/webp' />
              <source srcSet={backdrop_png} type='image/png' />
              <img className='backdrop1' style={{ borderRadius: '8px 20px 8px 20px', border: '7px solid #4588aa' }} src={backdrop_webp} alt="backdrop" />
            </picture>
            <picture id="drone1" alt="drone1" className='news-drone1' ref={droneRef}>
              <source srcSet={drone1_webp} type='image/webp' />
              <source srcSet={drone1_png} type='image/png' />
              <img src={drone1_webp} alt="logo" className='news-drone1' />
            </picture>
          </div>
        </div>
        <div className="news-image-container_mobile ">
          <picture id="drone_mobile" alt="drone_mobile" className='news-drone_mobile'>
            <source srcSet={drone_combat_mobile_webp} type='image/webp' />
            <source srcSet={drone_combat_mobile_png} type='image/png' />
            <img src={drone_combat_mobile_webp} alt='news-drone_mobile' />
          </picture>
        </div>
      </div>
       */}
    </div>
  )
};

export default News;