import React from 'react';
import './Carousel.css';

import videoUrl from '../../assets/video/about-us.webm';

import logo from '../../assets/images/PaperCactusLogo2.webp';
import logo_safari from '../../assets/images/PaperCactusLogo2.png';

const AboutCarousel = () => {

  return (

    <div className="carousel">
      <video className="background-video" autoPlay loop muted>
        <source src={videoUrl} type="video/webm" />
      </video>
      
      <div className="overlay-content">
            <div className='page__header-title'>
                <div className='page__general__divider'></div>
                <h1 style={{marginBottom: '-10px', fontSize:'55px'}}>About Us</h1>
                <p style={{fontSize:'1.6rem'}}>Paper Cactus Games</p>
            </div>

            <div className='aboutus__general'>
              <picture>
                <source srcSet={logo} type='image/webp'/>
                <source srcSet={logo_safari} type='image/png'/>
                <img className="papercactus__aboutus-general-image" src={logo} alt="pc_logo"/>
              </picture>
              <div className='aboutus__general__text'>
                <p>Founded in 2022, Paper Cactus Games is an up-and-coming Australian based Indie Video Game Company. Paper Cactus Games aims to be a long term predominantly game studio based in Adelaide, Australia; with a focus on 2.5D games.<br/> <br/>We are developing innovative and engaging gameplay with a focus on the rogue-like video game genre. 
                We are a small but dedicated team aiming to become a permanent fixture in the Game Developer space.
                </p>
              </div>
            </div>
      </div>
    </div>
  );
};

export default AboutCarousel;