import React from 'react';
import {Screenshots, FnSunite, WhatFoxNShadow, Header, Footer, Social, News } from '../../containers';
import { Navbar, Mail, Slogan, } from '../../components';
import { Parallax } from '@react-spring/parallax';

const FoxAndShadowPage = () => {
  return (
    <div className="FoxAndShadow_desktop">
        <div className="loader-container" id="loader-container">
          <Navbar navbarstyle="papercactus__navbar_loading" />
          <div className="loader-container__background"></div>
          <div className="hex"></div>
          <div className="spinner">
            <div className="spinner__center"></div>
            <div className="spinner__left"></div>
            <div className="circle__left"></div>
            <div className="spinner__right"></div>
            <div className="circle__right"></div>
          </div>
        </div> 
      <Parallax id="parallax">
          <Header />
          <Slogan style={{zIndex:'100',}}/>
          <Social />
          <News />
          <FnSunite />
          <WhatFoxNShadow />
          <Screenshots />
          <Mail />
          <Footer />
      </Parallax>
    </div>
    
  )
  }

export default FoxAndShadowPage;