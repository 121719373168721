import React from 'react';
import './mail.css';
import MailchimpForm from './mailchimpForm';
import AnimationPlayOnSectionAppear from '../../hooks/AnimationPlayOnSectionAppear';

import LeftMargin from '../../assets/images/LeftMarginb-blue.svg';
import LeftMarginPNG from '../../assets/images/LeftMarginb-blue.png';

import RightMargin from '../../assets/images/RightMarginb-blue.svg';
import RightMarginPNG from '../../assets/images/RightMarginb-blue.png';

import side_swarm_panel_1 from '../../assets/images/Swam1.jpg';
import side_swarm_panel_2 from '../../assets/images/Swam2.jpg';

const Mail = () => {
  return (

  <div className='papercactus__mail_backgroundColor'>
    <a id="mailinglist"/>
    <AnimationPlayOnSectionAppear className='fade-in-section'>
      <div className='papercactus__CTA'> 
        <picture id="leftbanner" className='papercactus__CTA-leftMargin' alt="margin-left-mail">
          <source srcSet={LeftMargin} type='image/webp' className='papercactus__CTA-leftMargin' />
          <source srcSet={LeftMarginPNG} type='image/png' className='papercactus__CTA-leftMargin'/>
          <img src={LeftMargin} alt="logo" className='papercactus__CTA-leftMargin'/>
        </picture>  
          <picture id="leftbanner" className='papercactus__CTA_preview_images'>
                <div className='division__CTA_panel_animation' style={{height:'640px', width:'177px', zIndex:'1',  backgroundImage: `url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='40' patternTransform='scale(1) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(212, 59%, 64%, 0.35)'/><path d='M0 10h20z' stroke-width='0.5' stroke='hsla(199, 100%, 76%, 0.29)' fill='none'/><path d='M0 30h20z' stroke-width='0.5' stroke='hsla(185, 0%, 100%, 0.35)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,-160)' fill='url(%23a)'/></svg>")` }}/>
                  <source srcSet={side_swarm_panel_1} type='image/jpg' />
                  <img src={side_swarm_panel_1} alt="logo"/>
          </picture>
        <picture id="leftbanner" className='papercactus__CTA-rightMargin' alt="margin-right-mail">
          <source srcSet={RightMargin} type='image/webp' className='papercactus__CTA-rightMargin' />
          <source srcSet={RightMarginPNG} type='image/png' className='papercactus__CTA-rightMargin'/>
          <img src={RightMargin} alt="logo" className='papercactus__CTA-rightMargin'/>
        </picture>  

        <div className='papercactus__CTA_container'>
          <picture id="leftbanner" className='papercactus__CTA-leftMargin' alt="margin-left-mail">
            <source srcSet={LeftMargin} type='image/webp' className='papercactus__CTA-leftMargin' />
            <source srcSet={LeftMarginPNG} type='image/png' className='papercactus__CTA-leftMargin'/>
            <img src={LeftMargin} alt="logo" className='papercactus__CTA-leftMargin'/>
          </picture>  
            <div className="division__CTA_panel_container">
              <div className='division__CTA_panel_animation' style={{height:'640px', width:'800px', zIndex:'-1',  backgroundImage: `url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='40' patternTransform='scale(1) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(212, 59%, 64%, 0.35)'/><path d='M0 10h20z' stroke-width='0.5' stroke='hsla(199, 100%, 76%, 0.29)' fill='none'/><path d='M0 30h20z' stroke-width='0.5' stroke='hsla(185, 0%, 100%, 0.35)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,-160)' fill='url(%23a)'/></svg>")` }}/>
                <div className='papercactus__CTA_panel_animation_gradiant_top'></div>
                <div className="division__CTA">
                  <h1>Want the Latest Updates?</h1>
                  <div className="division__CTA-mailchimp">
                    <MailchimpForm />
                  </div>
                  <div className="division__CTA-discord">
                    <p>Join the Paper Cactus Games Discord</p>
                    <a className='papercactus__socials-social_icon' href="https://discord.com/invite/XuXajDW" target="_blank" rel="noreferrer">
                          <i className="fa-brands fa-discord" style={{fontSize:'2.2rem'}}></i>
                    </a>
                  </div>
                </div>
            </div>
          <picture id="leftbanner" className='papercactus__CTA-rightMargin' alt="margin-right-mail">
            <source srcSet={RightMargin} type='image/webp' className='papercactus__CTA-rightMargin' />
            <source srcSet={RightMarginPNG} type='image/png' className='papercactus__CTA-rightMargin'/>
            <img src={RightMargin} alt="logo" className='papercactus__CTA-rightMargin'/>
          </picture>  
        </div>

        <picture id="rightbanner" className='papercactus__CTA-leftMargin' alt="margin-left-mail">
          <source srcSet={LeftMargin} type='image/webp' className='papercactus__CTA-leftMargin' />
          <source srcSet={LeftMarginPNG} type='image/png' className='papercactus__CTA-leftMargin'/>
          <img src={LeftMargin} alt="logo" className='papercactus__CTA-leftMargin'/>
        </picture>  
        <picture id="rightbanner" className='papercactus__CTA_preview_images'>
          <div className='division__CTA_panel_animation' style={{height:'640px', width:'177px', zIndex:'1', backgroundImage: `url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='40' patternTransform='scale(1) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(212, 59%, 64%, 0.35)'/><path d='M0 10h20z' stroke-width='0.5' stroke='hsla(199, 100%, 76%, 0.29)' fill='none'/><path d='M0 30h20z' stroke-width='0.5' stroke='hsla(185, 0%, 100%, 0.35)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,-160)' fill='url(%23a)'/></svg>")` }}/>
          <source srcSet={side_swarm_panel_2} type='image/jpg' />
          <img src={side_swarm_panel_2} alt="logo"/>
        </picture>
        <picture id="rightbanner" className='papercactus__CTA-rightMargin' alt="margin-right-mail">
          <source srcSet={RightMargin} type='image/webp' className='papercactus__CTA-rightMargin' />
          <source srcSet={RightMarginPNG} type='image/png' className='papercactus__CTA-rightMargin'/>
          <img src={RightMargin} alt="logo" className='papercactus__CTA-rightMargin'/>
        </picture>  
    </div>
    </AnimationPlayOnSectionAppear>
  </div>
  )
};

export default Mail;