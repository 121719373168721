import React, { useState, useRef, useEffect } from "react";

function AnimationPlayOnSectionAppear(props) {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();
  
  useEffect(() => {
    const currentRef = domRef.current; // Assign domRef.current to a variable
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });
    
    observer.observe(currentRef);
    
    return () => observer.unobserve(currentRef); // Use the variable in the cleanup function
  }, []);

  return (
    <div
      className={`${props.className} ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

export default AnimationPlayOnSectionAppear;