import React, { useRef, useEffect } from 'react';
import { ReactSVG } from "react-svg";

import './screenshots.css';

import digitalDisplay3 from '../../assets/images/misc/digitalDisplay3.svg';
import TitleBacking from '../../assets/images/title-back-3.svg';

import ScreenshotGallery from './ScreenshotGallery';

const Screenshots = () => {
  const svgRef = useRef(null);
  const svgRef2 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          svgRef.current.classList.add('animation');
          svgRef2.current.classList.add('animation');
          observer.unobserve(entries[0].target);
        }
      },
      {
        threshold: 1, 
      }
    );
    
    observer.observe(svgRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className='papercactus__screenshots section__padding' id="whatFoxNShadow">
      <div className="background-gradient-right"></div>
      <div className="background-gradient-left"></div>
      <div className='papercactus__whatFoxNShadow-screenshots'>
        <div class="papercactus__cyberpunk-hud">
            <div className='digitalDisplay3 left' ref={svgRef}><ReactSVG src={digitalDisplay3}/></div>
            <div className='digitalDisplay3 right' ref={svgRef2}><ReactSVG src={digitalDisplay3}/></div>
          </div>
            <img className="papercactus__choices-header-image" src={TitleBacking} alt="title-backdrop" />

            <h1 style={{ marginBottom: '10px', letterSpacing: '0.1rem' }}>Your Choices Matter</h1>
          
          <p className='papercactus__choices'>The decks that the drones are equipped with are for more than battle. These tools can be used in a diverse range of situations for utility, decision making, and to provide reliable social behaviours for each Fox to draw from. <br/><br/></p>
          
          <p className='papercactus__choices'> Depending on how you run your deck, you may find opportunities to exercise your cunning, see different details about where you are, or make enemies out of the other inhabitants ghosting about in the scrapping grounds.</p>
        </div>
        <div className="papercactus__screenshots-container">
          <ScreenshotGallery/>
        </div>
    </div>
  )

};

export default Screenshots;
