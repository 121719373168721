import 'photoswipe/dist/photoswipe.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

import FnS_logo from '../../assets/images/FnS_logo.png';
import FnS_logoThumbnail from '../../assets/images/FnSPressKit/thumbnails/FnS_logo_thumbnail.png';
import pgc_logo from '../../assets/images/PaperCactusLogo2.png';
import pgc_logoThumbnail from '../../assets/images/FnSPressKit/thumbnails/PaperCactusLogo2_thumbnail.png';
import pgc_logo_long from '../../assets/images/PCG-logo_long.png'
import pgc_logo_longThumbnail from '../../assets/images/FnSPressKit/thumbnails/PCG-logo_long_thumbnail.png'


const FnSpressKitLogos = () => {
  return (
  <Gallery>
    <Item
      original={FnS_logo}
      width="409"
      height="390"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={FnS_logoThumbnail} alt="" />
      )}
    </Item>
    <Item
      original={pgc_logo}
      width="1200"
      height="1200"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={pgc_logoThumbnail} alt=""/>
      )}
    </Item>
    <Item
      original={pgc_logo_long}
      width="1064"
      height="511"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={pgc_logo_longThumbnail} alt=""/>
      )}
    </Item>
  </Gallery>
  )
};

export default FnSpressKitLogos;