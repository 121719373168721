import React, { useRef, useState } from 'react';

import './aboutus.css';
import {Navbar} from '../../components';
import {Footer} from '../../containers';
import AboutCarousel from './AboutCarousel';

import pcg_photo1 from '../../assets/images/gamesplus.webp';
import pcg_photo1_safari from '../../assets/images/gamesplus.jpg';

import logo from '../../assets/images/PaperCactusLogo2.webp';
import logo_safari from '../../assets/images/PaperCactusLogo2.png';

import staff_leo from '../../assets/images/BustLeo.png';
import staff_jacko from '../../assets/images/BustJacko.png';
import staff_chris from '../../assets/images/BustChris.png';
import staff_john from '../../assets/images/BustJohn.png';
import staff_zen from '../../assets/images/bustZen.png';
import staff_sapph from '../../assets/images/BustSapph.png';
import staff_andrew from '../../assets/images/BustAndrew.png';

function Aboutus () {
  const cardRef1 = useRef(null);
  const [isFlipped1, setIsFlipped1] = useState(false);

  const cardRef2 = useRef(null);
  const [isFlipped2, setIsFlipped2] = useState(false);

  const cardRef3 = useRef(null);
  const [isFlipped3, setIsFlipped3] = useState(false);

  const cardRef4 = useRef(null);
  const [isFlipped4, setIsFlipped4] = useState(false);

  const cardRef5 = useRef(null);
  const [isFlipped5, setIsFlipped5] = useState(false);

  const cardRef6 = useRef(null);
  const [isFlipped6, setIsFlipped6] = useState(false);

  const cardRef7 = useRef(null);
  const [isFlipped7, setIsFlipped7] = useState(false);
  
  let xRotation = 0;
  let yRotation = 0;
  
  const handleMouseMove = (event, cardRef, isFlipped) => {
    const { clientX, clientY } = event;
    const { top, left, width, height } = cardRef.current.getBoundingClientRect();
    const xPos = clientX - left - width / 2;
    const yPos = clientY - top - height / 2;
  
    xRotation = (yPos / (height / 2)) * -22; // max 20deg rotation
    yRotation = (xPos / (width / 2)) * 27; // max 20deg rotation
    //const angle = Math.atan2(yPos, xPos) * (180 / Math.PI);
  
    if (isFlipped) {
      cardRef.current.style.transform = `rotateX(${xRotation}deg) rotateY(${yRotation + 180}deg) scale(1)`;
    } else {
      cardRef.current.style.transform = `rotateX(${xRotation}deg) rotateY(${yRotation}deg) scale(1)`;
    }
  
    cardRef.current.style.setProperty('--mouse-x', xPos + 'px');
    cardRef.current.style.setProperty('--mouse-y', yPos + 'px');
  
    if (Math.abs(xRotation) > 14 || Math.abs(yRotation) > 15) {
      cardRef.current.classList.add('shine');
    } else {
      cardRef.current.classList.remove('shine');
    }
  };
  
  const handleFlip = (setIsFlipped, cardRef, isFlipped) => {
    setIsFlipped(prevIsFlipped => {
      const newIsFlipped = !prevIsFlipped;
      if (newIsFlipped) {
        cardRef.current.style.transform = `rotateX(${xRotation}deg) rotateY(${yRotation + 180}deg)`;
        cardRef.current.querySelector('.card-back').style.visibility = 'visible';
      } else {
        cardRef.current.style.transform = `rotateX(${xRotation}deg) rotateY(${yRotation}deg)`;
        cardRef.current.querySelector('.card-back').style.visibility = 'hidden';
      }
      return newIsFlipped;
    });
  };
  
  const handleMouseLeave = (cardRef, setIsFlipped) => {
    cardRef.current.style.transform = 'rotateX(0deg) rotateY(0deg)';
    cardRef.current.classList.remove('shine');
    cardRef.current.querySelector('.card-back').style.visibility = 'hidden';
    setIsFlipped(false);
  
    setTimeout(() => {
      cardRef.current.style.setProperty('--mouse-x', '0px');
      cardRef.current.style.setProperty('--mouse-y', '0px');
    }, 300); // delay of 300 milliseconds
  };

  return (
    <div className='AboutUs '>  
              <Navbar navbarstyle="papercactus__navbar" />
        <div className="aboutus__background__container">
          <div className='aboutus__black__divider'></div>
          <AboutCarousel />
          <div className='aboutus__general__container'>
              <div className='aboutus__general__location'>
                <picture id='picture1'>
                    <source srcSet={pcg_photo1} type='image/webp'/>
                    <source srcSet={pcg_photo1_safari} type='image/png'/>
                    <img className="papercactus__aboutus-general-image" alt="pc_logo"/>
                </picture>
                <iframe 
                    title="background-aboutus-video"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3271.2715227381314!2d138.6000671!3d-34.9247267!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0cf17086cd54d%3A0x12387f5f7c07d0ce!2sGame%20Plus!5e0!3m2!1sen!2sjp!4v1715096168271!5m2!1sen!2sjp" 
                    style={{width:"400px", height:"300px", border:"0", paddingRight: '20px'}} 
                    allowFullScreen 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
                <div className='aboutus__location_text'>
                    <span>Paper Cactus Games</span>
                    <span>Games Plus</span>
                    <span>Level 2/44 Pirie St</span>
                    <span>Adelaide, South Australia 5000 </span>
                    <span> Australia</span>
                    <br/>
                    <a className='aboutus__contact-link' href='mailto:contact@papercactusgames.com'>contact@papercactusgames.com</a>
                </div>
              </div>
          </div>
          <div className='aboutus__staff-container'>
            <div className='aboutus__staff__text '>
                <div className='page__general__divider'></div>
                <h1>Team</h1>
            </div>
            <div className='aboutus__staff'>


            <div className='aboutus__staff__column'>
              <div 
                ref={cardRef1} 
                className='aboutus__staff__card tilting-card-body card-with-triangle'
                onMouseEnter={(e) => handleMouseMove(e, cardRef1, isFlipped1)} 
                onMouseMove={(e) => handleMouseMove(e, cardRef1, isFlipped1)} 
                onMouseLeave={() => handleMouseLeave(cardRef1, setIsFlipped1)}
              >
                <div className='card-back'>
                  <div className='card-back-border'>
                    <picture>
                      <source srcSet={logo} type='image/webp'/>
                      <source srcSet={logo_safari} type='image/png'/>
                      <img className="papercactus__aboutus-card-back-image" src={logo} alt="pc_logo"/>
                    </picture>
                  </div> 
                </div>
                <div className='triangle-text' onClick={() => handleFlip(setIsFlipped1, cardRef1, isFlipped1)}>Flip</div>
                <img src={staff_leo} alt="staff-bust-leo"/>
                <div id='staff_info'>
                  <h3>Leo Cheung</h3>
                  <p>Director</p>
                  <p>Lead Artist</p>
                  <a href='https://x.com/BlueTwoDays' target="_blank" rel="noreferrer">
                    <i class="fa-brands fa-square-x-twitter"></i> bluetwodays 
                  </a>
                </div>
                
              </div>
              <div className='aboutus__staff__column_back-card' style={{backgroundColor:'#5d8758'}}/>
            </div>

            <div className='aboutus__staff__column'>
                <div 
                  ref={cardRef2} 
                  className='aboutus__staff__card tilting-card-body card-with-triangle'
                  onMouseEnter={(e) => handleMouseMove(e, cardRef2, isFlipped2)} 
                  onMouseMove={(e) => handleMouseMove(e, cardRef2, isFlipped2)} 
                  onMouseLeave={() => handleMouseLeave(cardRef2, setIsFlipped2)}
                >
                  <div className='card-back'>
                    <div className='card-back-border'>
                      <picture>
                        <source srcSet={logo} type='image/webp'/>
                        <source srcSet={logo_safari} type='image/png'/>
                        <img className="papercactus__aboutus-card-back-image" src={logo} alt="pc_logo"/>
                      </picture>
                    </div> 
                  </div>
                  <div className='triangle-text' onClick={() => handleFlip(setIsFlipped2, cardRef2, isFlipped2)}>Flip</div>
                  <img src={staff_jacko} alt="staff-bust-jacko"/>
                  <div id='staff_info'>
                    <h3>Jackson Michael</h3>
                    <p>Lead Programer</p>
                    <p>Game Designer</p>
                  </div>
                </div>
              <div className='aboutus__staff__column_back-card' style={{backgroundColor:'#87a384'}}/>
            </div>  
              <div className='aboutus__staff__column'>
                <div 
                    ref={cardRef3} 
                    className='aboutus__staff__card tilting-card-body card-with-triangle'
                    onMouseEnter={(e) => handleMouseMove(e, cardRef3, isFlipped3)} 
                    onMouseMove={(e) => handleMouseMove(e, cardRef3, isFlipped3)} 
                    onMouseLeave={() => handleMouseLeave(cardRef3, setIsFlipped3)}
                  >
                    <div className='card-back'>
                      <div className='card-back-border'>
                        <picture>
                          <source srcSet={logo} type='image/webp'/>
                          <source srcSet={logo_safari} type='image/png'/>
                          <img className="papercactus__aboutus-card-back-image" src={logo} alt="pc_logo"/>
                        </picture>
                      </div> 
                    </div>
                    <div className='triangle-text' onClick={() => handleFlip(setIsFlipped3, cardRef3, isFlipped3)}>Flip</div>
                  <img src={staff_sapph} alt="staff-bust-sapph"/>
                  <div id='staff_info'>
                    <h3>Sapphire Van Veen</h3>
                    <p>Lead Designer</p>
                    <p>Game Designer</p>
                    <a href='https://x.com/Sapphirris' target="_blank" rel="noreferrer"><i className="fa-brands fa-square-x-twitter"></i> Sapphirris</a>
                  </div>
                </div>
                <div className='aboutus__staff__column_back-card' style={{backgroundColor:'#438a4b'}}/>
              </div>  
            </div>

            <div className='aboutus__staff'>

            <div className='aboutus__staff__column'>
              <div 
                  ref={cardRef4} 
                  className='aboutus__staff__card tilting-card-body card-with-triangle'
                  onMouseEnter={(e) => handleMouseMove(e, cardRef4, isFlipped4)} 
                  onMouseMove={(e) => handleMouseMove(e, cardRef4, isFlipped4)} 
                  onMouseLeave={() => handleMouseLeave(cardRef4, setIsFlipped4)}
                >
                    <div className='card-back'>
                      <div className='card-back-border'>
                        <picture>
                          <source srcSet={logo} type='image/webp'/>
                          <source srcSet={logo_safari} type='image/png'/>
                          <img className="papercactus__aboutus-card-back-image" src={logo} alt="pc_logo"/>
                        </picture>
                      </div> 
                    </div>
                    <div className='triangle-text' onClick={() => handleFlip(setIsFlipped4, cardRef4, isFlipped4)}>Flip</div>

                  <img src={staff_zen} alt="staff-bust-zen"/>
                  <div id='staff_info'>
                    <h3>Zenners</h3>
                    <p>Narrative Writer</p>
                    <a href='https://x.com/ZenFehr' target="_blank" rel="noreferrer"><i className="fa-brands fa-square-x-twitter"></i> ZenFehr</a>
                  </div>
                </div>
              <div className='aboutus__staff__column_back-card' style={{backgroundColor:'#438a72'}}/>
            </div>
            
            <div className='aboutus__staff__column'>
                <div 
                  ref={cardRef5} 
                  className='aboutus__staff__card tilting-card-body card-with-triangle'
                  onMouseEnter={(e) => handleMouseMove(e, cardRef5, isFlipped5)} 
                  onMouseMove={(e) => handleMouseMove(e, cardRef5, isFlipped5)} 
                  onMouseLeave={() => handleMouseLeave(cardRef5, setIsFlipped5)}
                >
                    <div className='card-back'>
                      <div className='card-back-border'>
                        <picture>
                          <source srcSet={logo} type='image/webp'/>
                          <source srcSet={logo_safari} type='image/png'/>
                          <img className="papercactus__aboutus-card-back-image" src={logo} alt="pc_logo"/>
                        </picture>
                      </div> 
                    </div>
                    <div className='triangle-text' onClick={() => handleFlip(setIsFlipped5, cardRef5, isFlipped5)}>Flip</div>
                  <img src={staff_chris} alt="staff-bust-chris"/>
                  <div id='staff_info'>
                    <h3>Chris Hill</h3>
                    <p>Environmental Design</p>
                  </div>
                </div>
              <div className='aboutus__staff__column_back-card' style={{backgroundColor:'#85a188'}}/>
            </div>

            <div className='aboutus__staff__column'>
                <div 
                  ref={cardRef6} 
                  className='aboutus__staff__card tilting-card-body card-with-triangle'
                  onMouseEnter={(e) => handleMouseMove(e, cardRef6, isFlipped6)} 
                  onMouseMove={(e) => handleMouseMove(e, cardRef6, isFlipped6)} 
                  onMouseLeave={() => handleMouseLeave(cardRef6, setIsFlipped6)}
                >
                    <div className='card-back'>
                      <div className='card-back-border'>
                        <picture>
                          <source srcSet={logo} type='image/webp'/>
                          <source srcSet={logo_safari} type='image/png'/>
                          <img className="papercactus__aboutus-card-back-image" src={logo} alt="pc_logo"/>
                        </picture>
                      </div> 
                    </div>
                    <div className='triangle-text' onClick={() => handleFlip(setIsFlipped6, cardRef6, isFlipped6)}>Flip</div>
                  <img src={staff_john} alt="staff-bust-john"/>
                  <div id='staff_info'>
                    <h3>John Oestmann</h3>
                    <p>Composer</p>
                    <a href='https://www.youtube.com/@johnoestmann-music' target="_blank" rel="noreferrer"><i className="fa-brands fa-youtube"></i> johnoestmann-music</a>
                  </div>
                </div>
              <div className='aboutus__staff__column_back-card' style={{backgroundColor:'#37523a'}}/>
            </div>  

            <div className='aboutus__staff__column'>
                <div 
                  ref={cardRef7} 
                  className='aboutus__staff__card tilting-card-body card-with-triangle'
                  onMouseEnter={(e) => handleMouseMove(e, cardRef7, isFlipped7)} 
                  onMouseMove={(e) => handleMouseMove(e, cardRef7, isFlipped7)} 
                  onMouseLeave={() => handleMouseLeave(cardRef7, setIsFlipped7)}
                >
                    <div className='card-back'>
                      <div className='card-back-border'>
                        <picture>
                          <source srcSet={logo} type='image/webp'/>
                          <source srcSet={logo_safari} type='image/png'/>
                          <img className="papercactus__aboutus-card-back-image" src={logo} alt="pc_logo"/>
                        </picture>
                      </div> 
                    </div>
                    <div className='triangle-text' onClick={() => handleFlip(setIsFlipped7, cardRef7, isFlipped7)}>Flip</div>
                  
                  <img src={staff_andrew} alt="staff-bust-andrew"/>
                  <div id='staff_info'>
                    <h3>Andrew Farmer</h3>
                    <p>Website Developer</p>
                    <a href='https://x.com/aclfarmer' target="_blank" rel="noreferrer"><i className="fa-brands fa-square-x-twitter"></i> aclfarmer</a>
                  </div>
                </div>
              <div className='aboutus__staff__column_back-card' style={{backgroundColor:'#608a7d'}}/>
            </div>  
          </div>
        </div>
      </div>
        <Footer />
    </div>

  )
}

export default Aboutus