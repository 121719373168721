import React from 'react'
import './FnSpresskit.css';

import {Navbar} from '../../components';
import {Footer} from '../../containers';

import FnSpressKitGallery from './FnSpressKitGallery';
import FnSpressKitLogos from './FnSpressKitLogos';

import FnS_logo from '../../assets/images/FnS_logo.png';

import FnSpress_header from '../../assets/images/banner-FnSpress.webp';
import FnSpress_header_safari from '../../assets/images/banner-FnSpress.png';

import news_1 from '../../assets/images/FnSPressKit/news/news-safc-feb-23.webp'
import news_1_safari from '../../assets/images/FnSPressKit/news/news-safc-feb-23.png'
import news_2 from '../../assets/images/FnSPressKit/news/news-kotaku-feb-23.webp'
import news_2_safari from '../../assets/images/FnSPressKit/news/news-kotaku-feb-23.png'
import news_3 from '../../assets/images/FnSPressKit/news/Paper-Cactus-Gamescom-2023.webp'
import news_3_safari from '../../assets/images/FnSPressKit/news/Paper-Cactus-Gamescom-2023.jpg'


const FnSpresskit = () => {
  return ( 
    <div className='papercactus__information'>
        <Navbar navbarstyle="papercactus__navbar" />
        <div className='page__header'>
          <div className='page__header-title'>
                <div className='page__general__divider'></div>
                <h1 style={{marginBottom:'-10px'}}>Fox and Shadow</h1>
                <p style={{fontSize:'1.6rem'}}>Press Kit</p>
          </div>
            <picture>
                <source srcSet={FnSpress_header} type='image/webp'/>
                <source srcSet={FnSpress_header_safari} type='image/png'/>
                <img className="papercactus__page-image" src={FnSpress_header} alt="aboutus-header"/>
            </picture>
        </div>
        <div className='aboutus__black__divider'></div>
            <div className='papercactusgames__baseBackground'>
                <div className='papercactus__pressKit-general-container'>
                    <div className="papercactus__information-side-navbar">
                            <div className="papercactus__information-side-navbar-nav">
                                        <div id='navbar-divider' className='page__general__divider'></div>
                                        <a href='#information'><p>Information</p></a>
                                        <a href='#images'><p>Game Images and Concept Art</p></a>
                                        <a href='#videos'><p>Videos</p></a>
                                        <a href='#logosandicons'><p>Logos and Icons</p></a>
                                        <a href='#news'><p>News Appearances</p></a>
                                        <a href='#contact'><p>Contact Team</p></a>
                            </div>
                    </div>
                    <div className='section__pressKit__padding papercactus__pressKit'>
                            <div className='papercactus__information-header'>
                                    <div className='page__general__divider'></div>
                                    <h1 id='information'>Information</h1>
                            </div>
                            <div className='papercactus__whatFoxNShadow-information-container'>

                                    <div className="papercactus__information-leftside">
                                            <img className='papercactus__information-logo' src={FnS_logo} alt="FnS" />
                                            <h1>GENRE</h1>
                                            <p>Deck-building Roguelike</p>
                                    </div>
                                    <div className='papercactus__whatFoxNShadow-information'>
                                        <div className='papercactus__information-text'>
                                            <h1>PLANNED PLATFORMS</h1>
                                            <p>PC, Nintendo Switch (TBC)</p>
                                            <h1>ESTIMATED COMPLETION DATE</h1>
                                            <p> End of Q2 2025 </p>
                                            <h1>SYNOPSIS</h1>
                                            <p>
                                            In this dual-deckbuilding roguelike, you'll need to balance moves from both your pilot and their drone to fight your way through a broken city, where rogue AI rules the streets. </p><br/>
                                            <p>While your fellow humans rest in stasis underground, only a savvy few are trusted to scrap, scavenge, and trade for the resources above-- anything it takes to keep the lights on.</p><br/> 
                                            <p>Are you worth the air you breathe?</p>
                                        </div>
                                    </div>
                            </div>
                            <div className='papercactus__information-promotional'>
                                <div id='images' className='page__general__divider'></div>
                                    <h1>Game Images and Concept Art</h1>
                                    <div className='FnSpressKitGallery__style'>
                                        <FnSpressKitGallery/>
                                    </div>
                            </div>

                            <div className='papercactus__information-promotional'>
                                <div id='videos' className='page__general__divider'></div>
                                    <h1>Videos</h1>
                                    <div className='papercactus__information-videos'>
                                        <h1>Coming Soon...</h1>
                                    </div>

                            </div>

                            <div className='papercactus__information-promotional'>
                                <div id='logosandicons' className='page__general__divider'></div>
                                    <h1>Logos and Icons</h1>
                                    <div className='FnSpressKitGallery__style'>
                                        <FnSpressKitLogos/>
                                    </div>

                            </div>
                            <div className='papercactus__information-promotional'>
                                <div id='news' className='page__general__divider'></div>
                                    <h1>News Apperances</h1>
                                    <div className='papercactus__information-news'>
                                        <div className='news-card'>
                                            <picture>
                                                <source srcSet={news_1} type='image/webp'/>
                                                <source srcSet={news_1_safari} type='image/png'/>
                                                <img className="papercactus__aboutus-general-image" alt="news1"/>
                                            </picture>
                                            <div className='news-card-content'> 
                                                <a href="https://www.safilm.com.au/latest-news/hundreds-turn-out-to-the-safcs-inaugural-sage-sa-game-exhibition/"><span>Hundreds turn out to the SAFC’s inaugural SAGE: SA Game Exhibition</span></a><br/>
                                                <span>South Australian Film Corporation</span>
                                            </div>
                                        </div>

                                        <div className='news-card'>
                                            <picture>
                                                <source srcSet={news_2} type='image/webp'/>
                                                <source srcSet={news_2_safari} type='image/png'/>
                                                <img className="papercactus__aboutus-general-image" alt="news1"/>
                                            </picture>
                                            <div className='news-card-content'> 
                                                <a href="https://www.kotaku.com.au/2023/02/south-australia-is-ready-to-be-our-next-major-games-development-hub/"><span>South Australia Is Ready To Be Our Next Major Games Development Hub</span></a><br/>
                                                <span>Kotaku Australia</span>
                                            </div>
                                        </div>

                                        <div className='news-card'>
                                            <picture>
                                                <source srcSet={news_3} type='image/webp'/>
                                                <source srcSet={news_3_safari} type='image/png'/>
                                                <img className="papercactus__aboutus-general-image" alt="news1"/>
                                            </picture>
                                            <div className='news-card-content'> 
                                                <a href="https://glamadelaide.com.au/south-australian-studios-level-up-for-worlds-largest-gaming-event/"><span>South Australian studios level up for world’s largest gaming event</span></a><br/>
                                                <span>Glam Adelaide</span>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className='papercactus__information-promotional'>
                                <div  id='contact' className='page__general__divider'></div>
                                        <h1>Contact Team</h1>
                                    <div className='papercactus__information-contactTeam'>
                                        <div className='papercactus__information-contactTeam-row1'>
                                            <p>Leo "BlueTwosdays" Cheung</p>
                                            <p style={{fontWeight:'200'}}>Director/Lead Artist</p>
                                            <br/>
                                            <p>Jackson Michael</p>
                                            <p style={{fontWeight:'200'}}>Lead Programmer</p>
                                            <br/>
                                            <p>Sapphire Van Veen</p>
                                            <p style={{fontWeight:'200'}}>Lead Designer</p>
                                            <br/>
                                            <p>Chris "Osmenn" Hill</p>
                                            <p style={{fontWeight:'200'}}>Environmental Artist</p>
                                            <br/>
                                            <p>John Oestmann</p>
                                            <p style={{fontWeight:'200'}}>Composer</p>
                                        </div>
                                        <div className='papercactus__information-contactTeam-row2'>
                                            <p style={{fontWeight:'600'}}>Inquiries</p>
                                            <a href="mailto:contact@papercactusgames.com"><p>contact@papercactusgames.com</p></a>
                                            <br/>
                                            <p style={{fontWeight:'600'}}>X</p>
                                            <a href="https://x.com/PapercactusGame"><p>https://x.com/PapercactusGame</p></a>
                                            <br/>
                                            <p style={{fontWeight:'600'}}>Discord</p>
                                            <a href="https://discord.gg/7szET4A"><p>https://discord.gg/7szET4A</p></a>
                                        </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
    <Footer/>
  </div>
  )
}

export default FnSpresskit