import 'photoswipe/dist/photoswipe.css'

import { Gallery, Item } from 'react-photoswipe-gallery'



import screen1Thumbnail from '../../assets/images/FnSPressKit/thumbnails/screen1-thumbnail.jpg';
import screen1Original from '../../assets/images/FnSPressKit/screen1_proto.png';
import screen2Thumbnail from '../../assets/images/FnSPressKit/thumbnails/screen2-thumbnail.jpg';
import screen2Original from '../../assets/images/FnSPressKit/screen2_proto.png';
import screen3Thumbnail from '../../assets/images/FnSPressKit/thumbnails/screen3-thumbnail.jpg';
import screen3Original from '../../assets/images/FnSPressKit/screen3_proto.png';
import screen4Thumbnail from '../../assets/images/FnSPressKit/thumbnails/Illustration_2-thumbnail.jpg';
import screen4Original from '../../assets/images/FnSPressKit/Illustration_2.png';

import screen6Thumbnail from '../../assets/images/FnSPressKit/thumbnails/Hotshot4-thumbnail.jpg';
import screen6Original from '../../assets/images/FnSPressKit/Hotshot4.png';
import screen7Thumbnail from '../../assets/images/FnSPressKit/thumbnails/HotshotSelected-thumbnail.jpg';
import screen7Original from '../../assets/images/FnSPressKit/HotshotSelected.png';
import screen8Thumbnail from '../../assets/images/FnSPressKit/thumbnails/Swarmhighlighted-thumbnail.jpg';
import screen8Original from '../../assets/images/FnSPressKit/Swarmhighlighted.png';
import screen9Thumbnail from '../../assets/images/FnSPressKit/thumbnails/SwarmSelected-thumbnail.jpg';
import screen9Original from '../../assets/images/FnSPressKit/SwarmSelected.png';
import screen10Thumbnail from '../../assets/images/FnSPressKit/thumbnails/screen3-thumbnail.png';
import screen10Original from '../../assets/images/FnSPressKit/Tracy Concepts.png';
import screen11Thumbnail from '../../assets/images/FnSPressKit/thumbnails/canon_thumbnail.jpg';
import screen11Original from '../../assets/images/FnSPressKit/021_Cannon Ball.jpg';
import screen12Thumbnail from '../../assets/images/FnSPressKit/thumbnails/defend_thumbnail.jpg';
import screen12Original from '../../assets/images/FnSPressKit/004_Defend.jpg';
import screen13Thumbnail from '../../assets/images/FnSPressKit/thumbnails/mob_thumbnail.jpg';
import screen13Original from '../../assets/images/FnSPressKit/026_MOb.jpg';
import screen14Thumbnail from '../../assets/images/FnSPressKit/thumbnails/Drones3-thumbnail.jpg';
import screen14Original from '../../assets/images/FnSPressKit/Drones3.png';
import screen15Thumbnail from '../../assets/images/FnSPressKit/thumbnails/Coverart-thumbnail.jpg';
import screen15Original from '../../assets/images/FnSPressKit/Coverart.png';
import screen16Thumbnail from '../../assets/images/FnSPressKit/thumbnails/CielConcepts-thumbnail.jpg';
import screen16Original from '../../assets/images/FnSPressKit/CielConcepts.png';

const FnSpressKitGallery = () => {
  return (
  <Gallery>
    <Item
      original={screen1Original}
      thumbnail={screen1Thumbnail}
      width="2576"
      height="1356"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen1Thumbnail} alt="" />
      )}
    </Item>
    <Item
      original={screen2Original}
      thumbnail={screen2Thumbnail}
      width="2584"
      height="1359"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen2Thumbnail} alt=""/>
      )}
    </Item>
    <Item
      original={screen3Original}
      thumbnail={screen3Thumbnail}
      width="1175"
      height="664"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen3Thumbnail} alt=""/>
      )}
    </Item>
    <Item
      original={screen4Original}
      thumbnail={screen4Thumbnail}
      width="1000"
      height="1400"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen4Thumbnail} alt="" />
      )}
    </Item>
    <Item
      original={screen6Original}
      thumbnail={screen6Thumbnail}
      width="1050"
      height="1150"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen6Thumbnail} alt="" />
      )}
    </Item>
    <Item
      original={screen7Original}
      thumbnail={screen7Thumbnail}
      width="1200"
      height="400"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen7Thumbnail} alt="" />
      )}
    </Item>
    <Item
      original={screen8Original}
      thumbnail={screen8Thumbnail}
      width="2000"
      height="6000"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen8Thumbnail} alt="" />
      )}
    </Item>
    <Item
      original={screen9Original}
      thumbnail={screen9Thumbnail}
      width="2000"
      height="6000"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen9Thumbnail} alt="" />
      )}
    </Item>
    <Item
      original={screen10Original}
      thumbnail={screen10Thumbnail}
      width="1400"
      height="1250"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen10Thumbnail} alt="" />
      )}
    </Item>
    <Item
      original={screen11Original}
      thumbnail={screen11Thumbnail}
      width="1024"
      height="1024"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen11Thumbnail} alt="" />
      )}
    </Item>
    <Item
      original={screen12Original}
      thumbnail={screen12Thumbnail}
      width="1024"
      height="1024"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen12Thumbnail} alt="" />
      )}
    </Item>
    <Item
      original={screen13Original}
      thumbnail={screen13Thumbnail}
      width="1024"
      height="1024"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen13Thumbnail} alt="" />
      )}
    </Item>
    <Item
      original={screen14Original}
      thumbnail={screen14Thumbnail}
      width="3000"
      height="2000"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen14Thumbnail} alt="" />
      )}
    </Item>
    <Item
      original={screen15Original}
      thumbnail={screen15Thumbnail}
      width="1600"
      height="1200"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen15Thumbnail} alt=""/>
      )}
    </Item>
    <Item
      original={screen16Original}
      thumbnail={screen16Thumbnail}
      width="1400"
      height="1250"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen16Thumbnail} alt=""/>
      )}
    </Item>
  </Gallery>
  )
};

export default FnSpressKitGallery;