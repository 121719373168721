import React, { useRef, useEffect } from 'react';
import { ReactSVG } from "react-svg";

import './FnSunite.css';

import conceptArt2 from '../../assets/images/concept_art2.jpg';
import LeftMargin from '../../assets/images/LeftMarginb-blue.svg';
import TitleBacking from '../../assets/images/title-back-3.svg';

import digitalDisplay1 from '../../assets/images/misc/digitalDisplay1.svg';
import digitalDisplay2 from '../../assets/images/misc/digitalDisplay2.svg';

import DeckDemo from '../../assets/images/deck_demo.png';

const FnSunite = () => {

  const svgRef = useRef(null);
  const svgRef2 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          svgRef.current.classList.add('animation');
          svgRef2.current.classList.add('animation');
          observer.unobserve(entries[0].target);
        }
      },
      {
        threshold: 1, 
      }
    );

    observer.observe(svgRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className='papercactus__drone-column-container section__padding'>
      <div className='papercactus__drone-row-container' id="whatFoxNShadow">
        <div class="papercactus__cyberpunk-hud">
          <div className='digitalDisplay1' ref={svgRef}><ReactSVG src={digitalDisplay1}/></div>
          <div className='digitalDisplay2' ref={svgRef2}><ReactSVG src={digitalDisplay2}/></div>
        </div>
        <div className="papercactus__drone-image-container">
          <img style={{borderRadius:'20px 8px 20px 8px', border: '7px solid #4588aa'}} className="papercactus__whatFoxNShadow-image" src={conceptArt2} alt="Concept of Character" loading="lazy"/>
        </div>
        <div className='papercactus__drone-paragraph-column'>
          <div className="papercactus__drone-title-backing">
              <img className="papercactus__drone-header-image" src={TitleBacking} alt="title-backdrop" />
              <h1 style={{ letterSpacing: '0.02rem' }}>Unite Reason and Emotion</h1>
          </div>
          <p className='papercactus__drone-paragraph'>Fox and Shadow is a deckbuilding Roguelike in which you'll manage two movesets to take down threats: one filled with directives that govern the operations of your drone, and the other that represents the more intuitive behaviours of your pilot. </p>
          <p>While most pilots have been forcibly desensitized to the grim reality of their world, the best among them, the Foxes, are encouraged to bring their own feelings into their work. It is a reluctant concession from their supervisor... there are just some things machines can't replicate.</p>
        </div>
          <img className='papercactus__drone-leftMarginFlipped' src={LeftMargin} alt="margin-left-top" />
      </div>
    <img className="papercactus__drone-deckDemo" src={DeckDemo} alt="Two Deck Demo" loading="lazy"/>
    </div>
  )

};
export default FnSunite
