import React from 'react';
import AnimationLink from './animation_link.js';

import './social.css';

const Social = () => {
  return ( 
    <div className='papercactusgames__social-container'>
        <div className='papercactusgames__social-head-title'><h1>Links</h1></div>
      <div className='papercactusgames__social-background-gradient'>
        <div className='papercactusgames__social'>

                          <AnimationLink
                  targetId={'socialActive_steam'}
                  to={'https://store.steampowered.com/app/2134370/Fox_and_Shadow/'}
                  dataValue={'STEAM'}
                  faIcon={'fa-brands fa-steam'}
                  isExternal={true}
                /> 
                          {/* 
          <div className='papercactus__socials-social_icon-disable'>

          <a>
            <i className="fa-brands fa-itch-io"/> Itch 
          </a>

          </div>          */}
                <AnimationLink
                  targetId={'socialActive_presskit'}
                  to={'/foxandshadow/presskit'}
                  dataValue={'PRESS KIT'}
                  faIcon={'fa-regular fa-newspaper'}
                /> 
                <AnimationLink
                  targetId={'socialActive_mailinglist'}
                  to={'#mailinglist'}
                  dataValue={'Mail List'}
                  faIcon={'fa-regular fa-envelope'}
                  isExternal={true}
                />
                <AnimationLink
                  targetId={'socialActive_discord'}
                  to={'https://discord.com/invite/XuXajDW'}
                  dataValue={'DISCORD'}
                  faIcon={'fa-brands fa-discord'}
                  isExternal={true}
                />
        </div>
      </div>
    </div>
  );
};

export default Social;