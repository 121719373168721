import mask from '../../assets/images/FNSparallax/foxmask-outline-minustext.png'

export const PARALLAX_IMAGES = [
    {
        id: 15,
        offset: 0,
        speed: 0,
        factor: 3,
        style: {display: "flex", justifyContent: "center", height: "1112px", top: "80px", willChange: 'transform'},
        mediaquery: 'header__15',
        url: require("../../assets/images/FNSparallax/2024-reduced/webp/15sky.webp"),
        url_safari: require("../../assets/images/FNSparallax/2024-reduced/png/15sky.png"),
        alt: 'sky'
    },
    {
        id: 14,
        offset: 0,
        speed: 0.08,
        factor: 3,
        style: {display: "flex", justifyContent: "center", height: "1112px", top: "80px", left: "-9px", willChange: 'transform', transform: 'translateZ(0.08)'},
        mediaquery: 'header__14',
        url: require("../../assets/images/FNSparallax/2024-reduced/webp/12-14.webp"),
        url_safari: require("../../assets/images/FNSparallax/2024-reduced/png/12-14.png"),
        alt: 'buildings'
    },
    {
        id: 13,
        offset: 0,
        speed: 0.2,
        factor: 3,
        style: {display: "flex", justifyContent: "center", height: "1112px", top: "78px", willChange: 'transform'},
        mediaquery: 'header__11',
        url: require('../../assets/images/FNSparallax/2024-reduced/webp/10-11.webp'),
        url_safari: require("../../assets/images/FNSparallax/2024-reduced/png/10-11.png"),
        alt: 'rubble'
    },
    {
        id: 12,
        offset: 0.05,
        speed: 0.3,
        factor: 3,
        style: {display: "flex", justifyContent: "center", height: "1112px", top: "35px", transform: 'translateZ(0.3)'},
        mediaquery: 'header__09',
        url: require('../../assets/images/FNSparallax/2024-reduced/webp/07-09.webp'),
        url_safari: require("../../assets/images/FNSparallax/2024-reduced/png/07-09.png"),
        alt: 'rubble'
    },
    {
        id: 11,
        offset: 0.05,
        speed: 0.4,
        factor: 0,
        style: {display: "flex", justifyContent: "center", height: "1112px", top: "340px"},
        mediaquery: 'header__08',
        url: require('../../assets/images/FNSparallax/2024-reduced/webp/03-06.webp'),
        url_safari: require("../../assets/images/FNSparallax/2024-reduced/png/03-06.png"),
        alt: 'building'
    },
    {
        id: 2.1,
        offset: 0,
        speed: 0.9,
        factor: 3,
        style: {display: "flex", margin: "0px auto", backgroundColor: "#002337", height: "350px", width: "1300px", top: "2350px", transform: 'translateZ(0.9)'},
        mediaquery: 'header__02-1',
        url: '',
        url_safari: '',
        alt: ''
    },
    {
        id: 2,
        offset: 0,
        speed: 0.9,
        factor: 3,
        style: {display: "flex", justifyContent: "center", height: "1312px", top: "1380px"},
        mediaquery: 'header__02',
        url: require('../../assets/images/FNSparallax/2024-reduced/webp/02Block.webp'),
        url_safari: require("../../assets/images/FNSparallax/2024-reduced/png/02Block.png"),
        alt: 'block'
    },
    {
        id: 1.5,
        offset: 0,
        speed: -0.6,
        factor: 3,
        style: {justifyContent: "center", top: "1100px", backgroundColor: "#222222", height: "470px", width: "100%", transform: 'translateZ(-0.6)'},
        mediaquery: 'header__01-5',
        url: '',
        url_safari:'',
        alt: '',
    },
    {
        id: 1,
        offset: 0,
        speed: -0.6,
        factor: 0,
        style: {display: "flex", justifyContent: "center", height: "1112px", top: "0px", transform: 'translateZ(-0.6)'},
        mediaquery: 'header__01',
        url: require('../../assets/images/FNSparallax/01Elevator.webp'),
        url_safari: require("../../assets/images/FNSparallax/safari/01Elevator.png"),
        alt: 'elevator'
    },
    {
        id: 0.5,
        offset: 0,
        speed: -0.6,
        factor: 0,
        style: {display: "flex", justifyContent: "center", height: "1050px", top: "-940px", width: "100%", background: "linear-gradient(0deg, rgba(0,2,34,0) 0%, rgba(0,2,34,1) 11%)", transform: 'translateZ(-0.6)'},
        mediaquery: 'header__0-5',
        url: '',
        url_safari: '',
        alt: ''
    },
    {
        id: 2.5,
        offset: 0,
        speed: -0.6,
        factor: 0,
        style: {display: "flex", justifyContent: "center", height: "800px", paddingRight:'20px', zIndex: "2", transform: 'translateZ(-0.6)'},
        mediaquery: 'header__02-5',
        mediaquery2: 'header__02-5_logo',
        url: require('../../assets/images/FNSparallax/2024-reduced/02.5Logo.png'),
        url_safari: require("../../assets/images/FNSparallax/2024-reduced/02.5Logo.png"),
        alt: 'logo'
    },
    {
        id: 2.4,
        offset: 0,
        speed: -0.6,
        factor: 0,
        style: {justifyContent: "center", height: "242px", width: '100%', zIndex: "2", display: "flex", left: "38px", top:'212px', maskImage: `url(${mask})`,  WebkitMaskImage: `url(${mask})`, maskRepeat: 'no-repeat', maskPosition: 'center 3px', maskSize: '210px auto'},
        mediaquery: 'header__02-5_logo-mask',
        url: '',
        url_safari: '',
        alt: ''
    },
    {
        id: 0,
        offset: 0,
        speed: 0.1,
        factor: 0,
        style: {display:"flex", justifyContent: "center", height: "1112px", top: "950px"},
        mediaquery: 'header__00',
        url: require('../../assets/images/FNSparallax/2024-reduced/00endv3.png'),
        url_safari: require("../../assets/images/FNSparallax/2024-reduced/00endv3.png"),
        alt: 'front rubble'
    }
]

