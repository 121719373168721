import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/PaperCactusLogo2.webp';

import './notfound.css';
import {Navbar} from '../../components';
import {Footer} from '../../containers';

const NotFound = () => (
  <div className="papercactusgames__baseBackground">
    <Navbar navbarstyle="papercactus__navbar" />
      <div className="papercactusgames__notFound__container section__padding">
        <img className="notFound__logo" src={logo} alt=""/>
        <h1 className="notFound__h1">404</h1>
        <h1>Cactus Not Found</h1>
        
        <h1 className='notFound-home'><Link to={'/'}>Home</Link></h1>
      </div>
    <Footer/>
  </div>
);

export default NotFound;