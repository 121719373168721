import React from 'react'
import './disclaimer.css';

import {Navbar} from '../../components';
import {Footer} from '../../containers';

const Disclaimer = () => {
  return ( 
    <div className='papercactus__information'>
        <Navbar navbarstyle="papercactus__navbar" />
        <div className='page__header'>
          <div className='page__header-title'>
                <div className='page__general__divider'></div>
                <h1 style={{marginBottom:'-10px'}}>Disclaimer</h1>
                <p style={{fontSize:'1.6rem'}}>Paper Cactus Games</p>
          </div>
          <div className="papercactus__page-image no-image-backcolor"/>
        </div>
        <div className='aboutus__black__divider'></div>
        <div className='papercactusgames__baseBackground'>
            <div className='papercactus__legal-general-container section__padding'>
              <div className='papercactus__legal'>
                <div className='page__general__divider'></div>
                <h1>General</h1>
                  <p>Thank you for visiting Paper Cactus Games. If you continue to browse and use the Paper Cactus Games website, you are agreeing to comply with and be bound by the following disclaimer, together with our terms and conditions of use.</p><br/>
                  <p> The information contained in this website is for general information purposes only and is provided by papercactusgames.com. While we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. It is important to make your own enquiries to determine if the information or products are appropriate for your intended use.</p><br/>
                  <p>In no event will Paper Cactus Games be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p><br/>
                  <p>Through this website you may be able to link to other websites which are not under the control of papercactusgames.com. We have no control over the nature, content and availability of those websites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p><br/>
                  <p>Every effort is made to maintain upkeep and make your experience as smooth as possible. Papercactusgames.com takes no responsibility for, and will not be held liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
              </div>

              <div className='papercactus__legal'>
                <div className='page__general__divider'></div>
                <h1>Copyright Notice</h1>
                  <p>This website and its contents are the copyright of Paper Games Games ©. All rights reserved.</p><br/>
                  <p>Any redistribution or reproduction of part or all of the contents in any form is prohibited other than the following. You may print or download contents to a local hard disk for your personal and non-commercial use only. You may copy some extracts only to individual third parties for their personal use, but only if you acknowledge the Paper Cactus Games website as the source of the material.</p><br/>
                  <p>You may not, except with our express written permission, distribute or commercially exploit the content. You may not transmit it or store it on any other website or other form of electronic retrieval system.</p><br/>
              </div>
            </div>
        </div>
    <Footer/>
  </div>
  )
}

export default Disclaimer