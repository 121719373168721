import 'photoswipe/dist/photoswipe.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

import screen1Thumbnail from '../../assets/images/thumbnails/screen1-thumbnail.jpg';
import screen1Original from '../../assets/images/screen1_proto.png';
import screen2Thumbnail from '../../assets/images/thumbnails/screen2-thumbnail.jpg';
import screen2Original from '../../assets/images/screen2_proto.png';
import screen3Thumbnail from '../../assets/images/thumbnails/screen3-thumbnail.jpg';
import screen3Original from '../../assets/images/screen3_proto.png';
import screen4Thumbnail from '../../assets/images/thumbnails/screen4-thumbnail.jpg';
import screen4Original from '../../assets/images/screen4.png';

const ScreenshotGallery = () => {
  return (
  <Gallery>
    <Item
      original={screen1Original}
      thumbnail={screen1Thumbnail}
      width="2576"
      height="1356"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen1Thumbnail} alt="" loading="lazy"/>
      )}
    </Item>
    <Item
      original={screen2Original}
      thumbnail={screen2Thumbnail}
      width="2584"
      height="1359"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen2Thumbnail} alt="" loading="lazy"/>
      )}
    </Item>
    <Item
      original={screen3Original}
      thumbnail={screen3Thumbnail}
      width="1175"
      height="664"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen3Thumbnail} alt="" loading="lazy"/>
      )}
    </Item>
    <Item
      original={screen4Original}
      thumbnail={screen4Thumbnail}
      width="1024"
      height="1024"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={screen4Thumbnail} alt="" loading="lazy"/>
      )}
    </Item>
  </Gallery>
  )
};

export default ScreenshotGallery;