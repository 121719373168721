import React from 'react';
import { Aboutus, FoxAndShadowPage, NotFound, FnSPressKit, PrivacyPolicy, Disclaimer} from './pages';
import { Routes, Route } from 'react-router-dom';
import './App.css';


class App extends React.Component {

  render() {
    return (
        <div className="App">
          <Routes>
            <Route path="*" element={<NotFound/>} />
            <Route exact path="/" element={<FoxAndShadowPage />} />
            <Route exact path="/foxandshadow" element={<FoxAndShadowPage />} />
            <Route exact path="/foxandshadow/presskit" element={<FnSPressKit/>}/>
            <Route exact path="/aboutus" element={<Aboutus />} />
            <Route exact path="/legal/privacypolicy" element={<PrivacyPolicy />} />
            <Route exact path="/legal/disclaimer" element={<Disclaimer />} />
          </Routes>
        </div>
      );
    }
};


export default App;


