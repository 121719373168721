import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import pcg_logo_long from '../../assets/images/PCG-logo_long.webp';
import pcg_logo_long_safari from '../../assets/images/PCG-logo_long.png';
import './navbar.css';

const Menu =() => (
    <>
    <Link to="/"><p className='navbar-spacing'>Fox and Shadow</p></Link>
    <Link to="/aboutus"><p className='navbar-spacing'>About Us</p></Link>
    </>
)

const Navbar = ({navbarstyle}) => {
    const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className={navbarstyle} id='#home'>
        <div className="papercactus__navbar-links_logo">
          <Link to="/">
            <picture>
              <source srcSet={pcg_logo_long} type='image/webp'/>
              <source srcSet={pcg_logo_long_safari} type='image/png' />
              <img src={pcg_logo_long} alt="logo" width='1064px' height='511px'/>
            </picture>
            </Link>
        </div>
      <div className="papercactus__navbar-links">
        <div className="papercactus__navbar-links_container">
            <Menu />
        </div>
      </div>
      <div className="papercactus__navbar-socials">
          <a href="https://x.com/PapercactusGame">
            <i className="fa-brands fa-square-x-twitter"></i>
          </a>
          <a href="https://instagram.com/PapercactusGames">
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCW4JLT0B1umnFijn8E7s5ZA">
            <i className="fa-brands fa-youtube"></i>
          </a>
          <a href="https://discord.com/invite/XuXajDW">
            <i className="fa-brands fa-discord"></i>
          </a>
      </div>
      <div className='papercactus__navbar-menu'>
      {toggleMenu
          ? <RiCloseLine color="#fff" size={42} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={42} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
            <div className="papercactus__navbar-menu_container slide-in-top">
                <div className="papercactus__navbar-menu_container-links">
                    <Menu/>
                </div>
            </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
