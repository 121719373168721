import React, {useState, useEffect} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import InputField from "../ui/InputField/InputField";

import { Link } from 'react-router-dom';

const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');

    useEffect(() => {
      if(status === "success") clearFields();
    }, [status])

    const clearFields = () => {
      setEmail('');
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
        });
    }


    return (
        <form className="mc__form" onSubmit={(e) => handleSubmit(e)}>
          <h3 className="mc__title">
            {status === "success" 
              ? "Success!" 
              : "Join our mailing list for future updates."
            }
          </h3>
          {'\n'}
          <p> Sign up to be the first to know about updates and promotions on <br/>Fox and Shadow and Paper Cactus Games.</p>
          {'\n'}
          {status !== "success" ? (
            <div className="mc__field-container">
              <p className='mc__field-container-textfield__label'>Email address</p>
              <InputField
                className='mc__field-container-textfield'
                onChangeHandler={setEmail}
                type="email"
                value={email}
                placeholder="your@email.com"
                isRequired
              />
            </div>
            ) : null}

            {
              status === 'success' ? null :
              <InputField
                    className='mc__field-container-subscribe'
                    label="Subscribe"
                    type="submit"
                    formValues={[email]}
                  />
            }

            <p className='papercactus__CTA-smalltext'>By clicking 'subscribe' you are agreeing that Paper Cactus Games are able to send promotional material in accordance to our <Link to= 'legal/privacypolicy'>Privacy Policy</Link>.</p>
            
  {status === "sending" && (
              <div className="mc__alert mc__alert--sending">
                <div className='loader'/>
              </div>
            )}
            {status === "error" && (
              <div 
                className="mc__alert mc__alert--error"
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
            
            {status === "success" && (
              <div
                className="mc__alert mc__alert--success"
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
              
            {status === null && (
              <div className='message-spacer'/>  
              )}
          </form>
      );
  };


const MailchimpFormContainer = props => {

    const postUrl = `https://papercactusgames.us18.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&amp;id=${process.env.REACT_APP_MAILCHIMP_ID}&amp;v_id=5262&amp;f_id=009d25e7f0`;

    return (
        <div className="mc__form-container">
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status} 
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>

    );
};

export default MailchimpFormContainer;
