import React from 'react'
import './privacypolicy.css';

import {Navbar} from '../../components';
import {Footer} from '../../containers';

const PrivacyPolicy = () => {
  return ( 
    <div className='papercactus__information'>
        <Navbar navbarstyle="papercactus__navbar" />
        <div className='page__header'>
          <div className='page__header-title'>
                <div className='page__general__divider'></div>
                <h1 style={{marginBottom:'-10px'}}>Prviacy Policy</h1>
                <p style={{fontSize:'1.6rem'}}>Paper Cactus Games</p>
          </div>
                <div className="papercactus__page-image no-image-backcolor"/>
        </div>
        <div className='aboutus__black__divider'></div>
        <div className='papercactusgames__baseBackground'>
            <div className='papercactus__legal-general-container section__padding'>
                  <div className='papercactus__legal'>
                    <p style={{paddingBottom:'25px'}}>At papercactusgames.com, we are committed to protecting your privacy as a customer and an online visitor to our website. We use the information we collect about you to maximize the services that we provide to you. We respect the privacy and confidentiality of the information provided by you and adhere to the Australian Privacy Principles. Please read our privacy policy below carefully.</p>
                    <div className='page__general__divider'></div>
                    <h1>INFORMATION WE COLLECT FROM YOU</h1>
                      <p>When signing up to our mailing list, we may obtain the following information about you: name and email address (together ‘Personal Data’).</p><br/>
                      <p>Our services are not directed to persons under the age of 13 years old and we do not knowingly collect Personal Data from anyone under the age of 13 years old. If we become aware that a child under 13 years old has provided us with Personal Data, we will delete that information as soon as possible. If you are the parent or guardian of a child and you believe that child has provided us with Personal Data without your consent, then please contact us at <a className='legal__link' href='mailto:contact@papercactusgames.com'>contact@papercactusgames.com</a></p><br/>
                      <p>You can review, correct, update or delete your Personal Data by contacting us directly via email at <a className='legal__link' href='mailto:contact@papercactusgames.com'>contact@papercactusgames.com</a></p>
                  </div>

                  <div className='papercactus__legal'>
                    <div className='page__general__divider'></div>
                    <h1>HOW WE USE YOUR INFORMATION</h1>
                      <p>Personally Identifiable Information: We use the information we collect to deliver our services to you, including: communicating with you, providing technical support, notifying you of updates and offers, sharing useful content, measuring customer satisfaction, diagnosing problems and providing you with a personalized website experience.</p><br/>
                      <p>Marketing communications are only sent to you if you have requested or subscribed to them. You can opt out of our marketing communications at any time by unsubscribing or emailing us and your request will be actioned immediately.</p><br/>
                      <p>Non-Personally Identifiable Information: We also use the information we collect in aggregated and anonymized forms to improve our services, including: administering our website, producing reports and analytics, advertising our products and services, identifying user demands and assisting in meeting customer needs generally.</p><br/>
                      <p>Any information you choose to make publicly available on the Paper Cactus Games website will be available for others to see. If you subsequently remove this information, copies may remain viewable in cached and archived pages on other websites or if others have copied or saved the information.</p>
                  </div>
                  
                  <div className='papercactus__legal'>
                    <div className='page__general__divider'></div>
                    <h1>STORAGE AND SECURITY OF YOUR INFORMATION</h1>
                      <p>We will use all reasonable means to protect the confidentiality of your Personal Data while in our possession or control.</p><br/>
                      <p>To enable us to deliver our services, we may transfer information that we collect about you, including Personal Data, across borders for storage and processing in countries other than Australia. If, in the event your Personal Data is transferred and processed outside Australia, it will only be transferred to countries that have adequate privacy protections. </p><br/>
                      <p>We retain your personal information for as long as needed to provide services to you and as otherwise necessary to comply with legal obligations, resolve disputes and enforce our agreements.</p><br/>
                      <p>In the event there is a breach of security and your Personal Data is compromised, we will promptly notify you in compliance with Act 26WA through 26WU of the Australian Privacy Act 1988.</p>
                  </div>
                  <div className='papercactus__legal'>
                    <div className='page__general__divider'></div>
                    <h1>COOKIES AND PIXELS</h1>
                      <p>A cookie is a small file placed in your web browser that collects information about your web browsing behaviour. Cookies allow a website to tailor its configuration to your needs and preferences. Cookies do not access information stored on your computer or any Personal Data (e.g. name, address, email address or telephone number). Most web browsers automatically accept cookies but you can choose to reject cookies by changing your browser settings. This may, however, prevent you from taking full advantage of the Paper Cactus Games website.</p><br/>
                      <p>While Paper Cactus Games does not record your usage whilst on our website, we use the Google Analytics cookie to notify us on web traffic behaviour such as where traffic comes from, pages visited, links clicked, and average time spent on page. The information collected from this cookie is used to further develop our website experience. The Google Analytics Privacy policy can be found <a href='https://www.google.com.au/analytics/learn/privacy.html' className='legal__link'>here.</a></p>
                  </div>
                  <div className='papercactus__legal'>
                    <div className='page__general__divider'></div>
                    <h1>SHARING YOUR INFORMATION WITH THIRD PARTIES</h1>
                        <p>We do not and will not sell or deal in Personal Data or any customer information of any kind.</p><br/>
                        <p>
                          Your Personal Data details are only disclosed to third party suppliers when it is required by law, for goods or services which you have purchased, for payment processing or to protect our copyright, trademarks and other legal rights.
                          To the extent that we do share your Personal Data with a service provider, we would only do so if that party has agreed to comply with our privacy standards as described in this privacy policy and in accordance with applicable law.
                          Our contracts with third parties prohibit them from using any of your Personal Data for any purpose other than that for which it was shared.
                      </p>
                  </div>
                  <div className='papercactus__legal'>
                    <div className='page__general__divider'></div>
                    <h1>DISCLOSURE OF YOUR INFORMATION</h1>
                      <p>
                      We may from time to time need to disclose certain information, which may include your Personal Data, to comply with a legal requirement, such as a law, regulation, court order, subpoena, warrant, in the course of a legal proceeding or in response to a law enforcement agency request. 
                      Also, we may use your Personal Data to protect the rights, property or safety of papercactusgames.com, our customers or third parties. </p><br/>
                      <p>If there is a change of control in one of our businesses (whether by merger, sale, transfer of assets or otherwise) customer information, which may include your Personal Data, it will be transferred to a purchaser under a confidentiality agreement. 
                      We would only disclose your Personal Data in good faith and where required by any of the above circumstances.
                      </p>
                  </div>
                  <div className='papercactus__legal'>
                    <div className='page__general__divider'></div>
                    <h1>LINKS TO OTHER WEBSITES</h1>
                      <p>
                      This website may contain links to other websites. 
                      These links are meant for your convenience only. 
                      Links to third party websites do not constitute sponsorship or endorsement or approval of these websites. 
                      Please be aware that we are not responsible for the privacy practices of such other websites. 
                      We encourage our users to be aware, when they leave our website, to read the privacy statements of each and every website that collects personally identifiable information. 
                      This privacy policy applies solely to information collected by papercactusgames.com
                      </p>
                  </div>
                  <div className='papercactus__legal'>
                    <div className='page__general__divider'></div>
                    <h1>CHANGE IN PRIVACY POLICY</h1>
                      <p>
                      As we plan to ensure our privacy policy remains current, this policy is subject to change. 
                      We may modify this policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications on this website. 
                      Please return periodically to review our privacy policy.
                      </p>
                  </div>
                  <div className='papercactus__legal'>
                    <div className='page__general__divider'></div>
                    <h1>CONTACT US</h1>
                      <p>
                      If you have any questions or concerns at any time about our privacy policy or the use of your Personal Data, 
                      please contact us at <a href='mailto:contact@papercactusgames.com' className='legal__link'>contact@papercactusgames.com</a> and we will endeavour to respond as soon as possible. 
                      </p>
                  </div>
                </div>
        </div>
    <Footer/>
  </div>
  )
}

export default PrivacyPolicy