import React, {useEffect, useState} from 'react';
import './header.css';

import { PARALLAX_IMAGES } from "./parallaxArray"
import { ParallaxLayer } from '@react-spring/parallax';
import { Navbar } from '../../components';

import mobileBackground from '../../assets/images/mobile-header-crop.webp';
import mobileBackground_safari from '../../assets/images/mobile-header-crop-safari.jpg';

import mobileLogo from '../../assets/images/FNSparallax/02.5Logo.png';
import end from '../../assets/images/FNSparallax/00endv3.png';
import arrow_down from '../../assets/images/arrow-scroll-down.svg';

function removeLoader (){
  setTimeout(() => {
    const ele = document.getElementById('loader-container')
      if(ele){
        ele.classList.add('hide')
        setTimeout(() => {
          ele.outerHTML = ''
        }, 100)
      }
  },300)
}

function scrollWin() {
  document.getElementById("parallax").scrollBy({
    top: 1300,
    behaviour: 'smooth'
 })
}

const Header = () => {
  const [imgsLoaded, setImgsLoaded] = useState(false)

  useEffect(() => {
    const loadImage = image => {
      if (image.url !== '') { 
      return new Promise((resolve, reject) => {
          const loadImg = new Image()
          loadImg.src = image.url
            loadImg.onload = () =>
                resolve(image.url)
            loadImg.onerror = err => reject(err)
        } 
      )
    }
  }

    Promise.all(PARALLAX_IMAGES.map(image => loadImage(image)))
      .then(() => setImgsLoaded(true))
      .then(() => removeLoader())
      .catch(err => console.log("Failed to load images", err))
    }, [])

  return (
  <div id="header">
        <ParallaxLayer style={{height:'100px', zIndex: '101'}}>
          <Navbar navbarstyle="papercactus__navbar" />
        </ParallaxLayer>
    <div className="papercactus__header">
        <ParallaxLayer style={{height:'0px', zIndex: '1'}}>
          <div className="papercactus__header-scroll-arrow-container">
            <div className='papercactus__header-scroll-arrow-desktop-container'>
              <img className="papercactus__header-scroll-arrow-desktop" src={arrow_down} alt="scroll arrow"/>
              <div className="papercactus__header-scroll-arrow-desktop-overlay" onClick={scrollWin}/>
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer className='header__h1' speed={-0.6} style={{
          display: 'flex',
          justifyContent: 'center',
          left: '15px',
          animationName: 'FnS-hover-2',
          animationDuration: '10s',
          animationIterationCount: 'infinite',
          color: '#000000',
          textShadow: '1px 1px 1px white',
          zIndex: '1',
          height: '42px',
          transform: 'translateZ(-0.6)'
          }}>
          <h1 className="header-announcement" style={{fontFamily: 'var(--font-family)'}}>Now in Combat Alpha</h1>
        </ParallaxLayer>
        
        {imgsLoaded ? (
          PARALLAX_IMAGES.map(image => (
            <ParallaxLayer key={image.id} offset={image.offset} speed={image.speed} factor={image.factor} style={image.style} className={image.mediaquery}>
              <picture>
                <source srcSet={image.url} type='image/webp'/>
                <source srcSet={image.url_safari} type='image/png'/>
                <img className={image.mediaquery2} key={image.id} src={image.url} alt={image.alt} />
              </picture>
            </ParallaxLayer>
          ))
 
        ) : (
        <div>
        </div>
        )}
        
    </div>
        <div className="papercactus__header-mobile">
          <div className='papercactus__header-mobile-images'>
            <img className='papercactus__header-mobile-images-logo' src={mobileLogo} alt="mobileLogo" width='409px' height='390px'/>
            <picture>
              <source srcSet={mobileBackground} type='image/webp'/>
              <source srcSet={mobileBackground_safari} type='image/png'/>
              <img className='papercactus__header-mobile-images papercactus__header-mobile-images-static' src={mobileBackground} alt="mobileBackground" width='536px'/>
            </picture>
            <img className='papercactus__header-mobile-images papercactus__header-mobile-images-end' src={end} alt="end" />
          </div>
        </div>
  </div>
  )
};

export default Header;