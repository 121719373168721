import React from 'react';
import './slogan.css';

const text = "The world is in ruin; salvage yourself."

const Slogon = () => {
  return (
    <div className='papercactus__slogan section__small__padding'>
      <h1 className='glitch layers hero mobile-gltich' data-text={text}><span>{text}</span></h1>
    </div>
  )
};

export default Slogon;
