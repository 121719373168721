import React from 'react';
import './whatFoxNShadow.css';
import world from '../../assets/images/concept_art2_hotshot.jpg';

import LeftMargin from '../../assets/images/LeftMarginb-blue.svg';
import LeftMarginPNG from '../../assets/images/LeftMarginb-blue.png';

import TitleBacking from '../../assets/images/title-back-3.svg';

const whatFoxNShadow = () => {
  return (
    <div className='papercactus__whatFoxNShadow_backgroundColor'>
      <div className='papercactus__whatFoxNShadow section__padding' id="whatFoxNShadow">
        <picture className='papercactus__whatFoxNshadow-leftMargin' alt="margin-left-top">
          <source srcSet={LeftMargin} type='image/webp' className='papercactus__whatFoxNshadow-leftMargin' />
          <source srcSet={LeftMarginPNG} type='image/png' className='papercactus__whatFoxNshadow-leftMargin'/>
          <img src={LeftMargin} alt="logo" className='papercactus__whatFoxNshadow-leftMargin'/>
        </picture>  
          <div className='papercactus__whatFoxNShadow-world'>
            <div className="papercactus__whatFoxNshadow-title-backing"> 
              <img className="papercactus__whatFoxNShadow-header-image" src={TitleBacking} alt="title-back" />
              <h1 style={{ letterSpacing: '0.1rem' }}>The World</h1>
            </div>
            <p className='papercactus__whatFoxNShadow-paragraph'>In a broken city, control has been surrendered to AIs. It’s not a bustling society; most humans remain in cryosleep, buried in bunkers deep underground. To survive, the few that remain awake must cooperate with the unseen management and pilot small drones to scavenge resources from the polluted world above.</p>
            <p>Are <span style={{fontWeight:'700'}}>you</span> worth the air you breathe?</p>
          </div>
          <div className="papercactus__whatFoxNShadow-container">
            <img style={{borderRadius:'8px 20px 8px 20px', border: '7px solid #4588aa'}} className="papercactus__whatFoxNShadow-image" src={world} alt="Concept of Hotshot"/>
          </div>
      </div>  
    </div> 
  )

};

export default whatFoxNShadow;
